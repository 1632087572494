import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  _RouteRecordBase,
} from "vue-router";
import store from "@/store/index";
import _ from "lodash"



/**
 *meta(配置项): title标题 icon菜单图标  是否隐藏下级列表:默认false padFlag是否隐藏24px的内边距:默认false
 */
declare module "vue-router" {
  interface _RouteRecordBase {
    hidden?: boolean | string | number;
  }
}
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/logo.vue'),
    meta: { title: '登录', menuHidden: true }
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/components/main.vue"),
    meta: { title: "首页", icon: "icon-shouye-01", padFlag: true },
    redirect: "/home/index",
    children: [
      {
        path: "/home/index",
        name: "home-index",
        meta: { title: "首页" },
        component: () => import("@/views/home/home.vue"),
      },
    ]
  },
  {
    path: "/sites",
    name: "sites",
    meta: { roles: ['root', 'admin'], title: "站点管理", icon: "icon-zhandian-01", padFlag: true},
    component: () => import("@/components/main.vue"),
    redirect: "/sites/mosth",
    children: [
      {
        path: "/sites/mosth",
        name: "sites-mosth",
        meta: { roles: ['root', 'admin'], title: "站点管理" },
        component: () => import("@/views/Sites/mosth.vue"),
      },
    ],
  },

  {
    path: "/statists",
    name: "statists",
    meta: { roles: ['root', 'admin'], title: "数据统计", icon: "icon-shujutongji-01", padFlag: true },
    redirect: "/statists/sum",
    component: () => import("@/components/main.vue"),
    children: [
      {
        path: "/statists/sum",
        name: "statists-sum",
        meta: { roles: ['root', 'admin'], title: "数据统计", role: 'admin,root' },
        component: () => import("@/views/statist/sum.vue"),
        redirect: "/statists/statist",
        children: [
          {
            path: "/statists/statist",
            name: "statists-statist",
            meta: { roles: ['root', 'admin'], title: "数据统计", icon: "icon-erji-shujutongji-01", role: 'admin,root' },
            component: () => import("@/views/statist/statist.vue"),
          },

          {
            path: "/statists/pknum",
            name: "statists-pknum",
            meta: { roles: ['root', 'admin'], title: "数据对比", icon: "icon-shujuduibi-01", role: 'admin,root' },
            component: () => import("@/views/statist/pknum.vue"),
          },
          {
            path: "/statists/trend",
            name: "statists-trend",
            meta: { roles: ['root', 'admin'], title: "趋势分析", icon: "icon-qushifenxi-01", role: 'admin,root' },
            component: () => import("@/views/statist/trend.vue"),
          },
          {
            path: "/statists/Visitip",
            name: "statists-Visitip",
            meta: { roles: ['root', 'admin'], title: "来访IP", icon: "icon-laifangIP-01", role: 'admin,root' },
            component: () => import("@/views/statist/Visitip.vue"),
          },
        ]
      },

    ],
  },
  {
    path: "/property",
    name: "property",
    meta: { roles: ['root', 'admin'], title: "资产状况", icon: "icon-wodegongdan-01" },
    redirect: "/property/index",
    component: () => import("@/components/main.vue"),
    children: [
      {
        path: "/property/index",
        name: "property-index",
        meta: { roles: ['root', 'admin'], title: "资产状况", role: 'admin,root' },
        component: () => import("@/views/property/index.vue"),
      },
    ],
  },
  {
    path: "/tlds",
    name: "tlds",
    meta: { roles: ['root', 'admin'], title: "域名申诉", icon: "icon-yumingshensu-01", },
    component: () => import("@/components/main.vue"),
    redirect: "/tlds/index",
    children: [
      {
        path: "/tlds/index",
        name: "tlds-index",
        meta: { roles: ['root', 'admin'], title: "域名申诉" },
        component: () => import("@/views/Sites/tlds.vue"),
      },
    ],
  },
  {
    path: "/massage",
    name: "massage",
    meta: { roles: ['root', 'admin'], title: "我的工单", icon: "icon-xiaoxitongzhi-01" },
    redirect: "/massage/work",
    component: () => import("@/components/main.vue"),
    children: [
      {
        path: "/massage/work",
        name: "massage-work",
        meta: { roles: ['root', 'admin'], title: "我的工单", role: 'admin,root' },
        component: () => import("@/views/massage/work.vue"),
      },
    ],
  },
    {
    path: "/sucsses",
    name: "sucsses",
    meta: { roles: ['root', 'admin'], title: "我的消息", icon: "icon-wodegongdan-01" },
    redirect: "/sucsses/index",
    component: () => import("@/components/main.vue"),
    children: [
      {
        path: "/sucsses/index",
        name: "sucsses-index",
        meta: { roles: ['root', 'admin'], title: "我的消息", role: 'admin,root' },
        component: () => import("@/views/sucsses/index.vue"),
      },
    ],
  },
  {
    path: "/user",
    name: "user",
    meta: { roles: ['root', 'admin'], title: "个人中心", icon: "icon-zhanghu-01" },
    redirect: "/user/index",
    component: () => import("@/components/main.vue"),
    children: [
      {
        path: "/user/index",
        name: "user-index",
        meta: { roles: ['root', 'admin'], title: "个人中心", role: 'admin,root' },
        component: () => import("@/views/user/index.vue"),
      },
    ],
  },
  {
    path: "/usebook",
    name: "usebook",
    meta: { roles: ['root', 'admin'], title: "使用教程", icon: "icon-shiyongjiaocheng-mianxing-011" },
    redirect: "/usebook/index",
    component: () => import("@/components/main.vue"),
    children: [
      {
        path: "/usebook/index",
        name: "usebook-index",
        meta: { roles: ['root', 'admin'], title: "使用教程", role: 'admin,root' },
        component: () => import("@/views/usebook/index.vue"),
      },
    ],
  },
]


store.state.menu = _.cloneDeep(routes);
const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});


// 全局守卫
router.beforeEach((to, from, next) => {
  const isLogin = store.state.accessToken ? true : false;
  if (to.path == '/') {
    store.state.accessToken = ''
    store.state.menu = []
    store.state.role = ''
    next()
  } else {
    if (isLogin) {
      store.state.menu = _.cloneDeep(routes);
      next()
    } else {
      next('/')
    }
  }

})

export default router;
