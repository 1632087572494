import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    height_box:'',
    accessToken: "",
    menu: {},
    role: null,
    num: "first",
    rules: {
      username: [
        {
          required: true,
          message: "用户名不能为空",
          trigger: "blur",
        },
      ],
      password: [
        {
          required: true,
          message: "密码不能为空",
          trigger: "blur",
        },
      ],
      domains: [
        {
          required: true,
          message: "请输入待跳转域名",
          trigger: "blur",
        },
      ],
      group_id: [
        {
          required: true,
          message: "请填写分组名称",
          trigger: "blur",
        },
      ],
      target_addr: [
        {
          required: true,
          message: "请输入目标地址",
          trigger: "blur",
        },
      ],
      text: [
        {
          required: true,
          message: "请填写分组名称",
          trigger: "blur",
        },
      ],
      name: [
        {
          required: true,
          message: "请输入名称",
          trigger: "blur",
        },
      ],
      strategy_group_ids: [
        {
          required: true,
          message: "请选择策略分组",
          trigger: "blur",
        },
      ],
      jump_url: [
        {
          required: true,
          message: "请输入目标战点",
          trigger: "blur",
        },
      ],
      stay: [
        {
          required: true,
          message: "请选择生效时间",
          trigger: "blur",
        },
      ],
      ips: [
        {
          required: true,
          message: "请输入IP",
          trigger: "blur",
        },
      ],
      userGroupIds: [
        {
          required: true,
          message: "请选择分组",
          trigger: "blur",
        },
      ],
      domain: [
        {
          required: true,
          message: "请输入主域名",
          trigger: "blur",
        },
      ],
      user_group_ids: [
        {
          required: true,
          message: "请选择用户分组",
          trigger: "blur",
        },
      ],
      binding_domain: [
        {
          required: true,
          message: "请绑定注册域名",
          trigger: "blur",
        },
      ],
    },
  },
  getters: {},
  mutations: {
    getcookes(state, date) {
      return (state.accessToken = date);
    },
    setcookes(state, data) {
      return (state.accessToken = data);
    },
    getrole(state, date) {
      state.role = date;
      return state.role;
    },
    getrnum(state, date) {
      state.num = date;
      return state;
    },
    heightConetnt(state, data){
      state.height_box = data
      return state
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
